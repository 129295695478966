import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetailsComponent } from './device-details/device-details.component';
import { DeviceData } from './device-data';
import { DeviceDataCollection } from './device-data-collection';
import { AngularFirestore } from '@angular/fire/firestore';
import { SaveReadingsDialogComponent } from './save-readings-dialog.component';
import { MatDialog } from '@angular/material';

import * as firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-stream-details',
  templateUrl: './stream-details.component.html',
  styleUrls: ['./stream-details.component.css']
})
export class StreamDetailsComponent implements OnInit, OnDestroy {
  @ViewChildren(DeviceDetailsComponent) deviceComponents: QueryList<DeviceDetailsComponent>;
  private sub: any;
  public streamId: string;
  public devices: Observable<any[]>;
  public savedReadings: Observable<DeviceDataCollection[]>;

  constructor(private route: ActivatedRoute, private router: Router, private db: AngularFirestore, private dialog: MatDialog) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.streamId = params['id'];
      this.devices = this.db.collection('streams').doc(this.streamId).collection('devices').snapshotChanges();
      this.savedReadings = this.db.collection('streams').doc(this.streamId).collection('saved_readings')
        .snapshotChanges().pipe(map(readings => {
          return readings.map(reading => {
            const data = reading.payload.doc.data() as DeviceDataCollection;
            const id = reading.payload.doc.id;
            return { id, ...data };
          });
        }));
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goBack() {
    this.router.navigate(['/']);
  }

  speedtestAll() {
    this.deviceComponents.forEach(device => device.onRunClick());
  }

  saveReadings() {
    const deviceDataCollection: DeviceDataCollection = {
      name: 'Saved reading',
      savedAt: Timestamp.now(),
      devices: []
    };
    this.deviceComponents.forEach(device => {
      const deviceData: DeviceData = {
        deviceId: device.deviceId,
        data: device.data,
        latency: device.latency,
        averageDL: device.averageDL,
        averageUL: device.averageUL
      };
      if (device.latency === undefined) {
        delete deviceData.latency;
      }
      if (device.averageDL === undefined) {
        delete deviceData.averageDL;
      }
      if (device.averageUL === undefined) {
        delete deviceData.averageUL;
      }
      deviceDataCollection.devices.push(deviceData);
    });

    const dialogRef = this.dialog.open(SaveReadingsDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        deviceDataCollection.name = result;
        this.db.collection('streams').doc(this.streamId).collection('saved_readings').add(deviceDataCollection);
      }
    });
  }

  updateName(savedDevices: DeviceDataCollection) {
    this.db.collection('streams').doc(this.streamId).collection('saved_readings').doc(savedDevices.id).update(savedDevices);
  }

  share() {
    console.log('share');
  }
}
