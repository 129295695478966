import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Measurement } from '../measurement';
import { Throughput } from '../throughput';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.css']
})
export class DeviceDetailsComponent implements OnInit, OnDestroy {
  @Input() streamId: string;
  @Input() deviceId: string;
  private timer;
  private token: string;
  public data: Measurement;
  public latency: Throughput;
  public averageDL: Throughput;
  public averageUL: Throughput;
  public isTestRunning = false;
  public dataTimeout = false;

  private tokenSub;
  private dataSub;
  private latencySub;
  private TPDLSub;
  private TPULSub;
  private speedtestSub;

  constructor(private db: AngularFirestore, private fns: AngularFireFunctions) { }

  ngOnInit() {
    // TODO Refactor into Observables (see stream-details.component.ts)
    this.tokenSub = this.db.collection('streams').doc(this.streamId)
      .collection('devices').doc(this.deviceId)
      .collection('token').valueChanges().subscribe(token => {
        this.token = token[0].token;
      });

    this.dataSub = this.db.collection('streams').doc(this.streamId)
      .collection('devices').doc(this.deviceId)
      .collection<Measurement>('data', ref => ref.orderBy('timestamp', 'desc').limit(1))
      .valueChanges().subscribe(data => {
        this.data = data[0];
        this.dataTimeout = false;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.dataTimeout = true, 5000);
      });

    this.latencySub = this.db.collection('streams').doc(this.streamId)
      .collection('devices').doc(this.deviceId)
      .collection<Throughput>('ping', ref => ref.orderBy('timestamp', 'desc').limit(1))
      .valueChanges().subscribe(tp => this.latency = tp[0]);

    this.TPDLSub = this.db.collection('streams').doc(this.streamId)
      .collection('devices').doc(this.deviceId)
      .collection<Throughput>('downloadTP', ref => ref.orderBy('timestamp', 'desc').limit(1))
      .valueChanges().subscribe(tp => this.averageDL = tp[0]);

    this.TPULSub = this.db.collection('streams').doc(this.streamId)
      .collection('devices').doc(this.deviceId)
      .collection<Throughput>('uploadTP', ref => ref.orderBy('timestamp', 'desc').limit(1))
      .valueChanges().subscribe(tp => {
        this.averageUL = tp[0];
        this.isTestRunning = false;
      });
  }

  ngOnDestroy() {
    this.tokenSub.unsubscribe();
    this.dataSub.unsubscribe();
    this.latencySub.unsubscribe();
    this.TPDLSub.unsubscribe();
    this.TPULSub.unsubscribe();
    if (this.speedtestSub) {
      this.speedtestSub.unsubscribe();
    }
  }

  onRunClick() {
    if (!this.dataTimeout && !this.isTestRunning) {
      const triggerSpeedtest = this.fns.httpsCallable('sendMessageToDevice');
      this.speedtestSub = triggerSpeedtest({token: this.token}).subscribe();
      this.isTestRunning = true;
    }
  }
}
