import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-stream-list',
  templateUrl: './stream-list.component.html',
  styleUrls: ['./stream-list.component.css']
})
export class StreamListComponent implements OnInit {
  private items: Observable<any[]>;
  private stream: string;
  private debug: string;

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private router: Router, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.items = this.db.collection('streams').snapshotChanges();
    this.debug = this.route.snapshot.queryParamMap.get('debug');
  }

  goToStream() {
    this.db.collection('streams').doc(this.stream).ref.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        this.router.navigate(['/stream', this.stream]);
      } else {
        this.snackbar.open('SignalStream not found!');
      }
    });
  }

  mychange(val) {
    let chStream = val.split('-').join('');
    if (chStream.length > 0) {
      chStream = chStream.match(new RegExp('.{1,3}', 'g')).join('-');
    }
    this.stream = chStream;
  }
}
