import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { StreamListComponent } from './stream-list/stream-list.component';
import { StreamDetailsComponent } from './stream-details/stream-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatCardModule, MatDividerModule, MatExpansionModule, MatInputModule,
  MatListModule, MatToolbarModule, MatTabsModule, MatDialogModule, MatIconModule, MatSnackBarModule
} from '@angular/material';
import { DeviceDetailsComponent } from './stream-details/device-details/device-details.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BargaugeComponent } from './bargauge/bargauge.component';
import { DeviceDetailsLayoutComponent } from './stream-details/device-details/device-details-layout.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { SaveReadingsDialogComponent } from './stream-details/save-readings-dialog.component';
import { FormsModule } from '@angular/forms';

const appRoutes: Routes = [
  {
    path: '',
    component: StreamListComponent
  },
  {
    path: 'stream/:id',
    component: StreamDetailsComponent
  }
  /*{ path: '',
    redirectTo: '/heroes',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }*/
];

@NgModule({
  declarations: [
    AppComponent,
    StreamListComponent,
    StreamDetailsComponent,
    DeviceDetailsComponent,
    BargaugeComponent,
    DeviceDetailsLayoutComponent,
    TimeAgoPipe,
    SaveReadingsDialogComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes // , { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    MatToolbarModule, MatButtonModule, MatListModule, MatCardModule, MatDividerModule, MatExpansionModule, MatInputModule,
    MatTabsModule, MatDialogModule, MatIconModule, MatSnackBarModule
  ],
  entryComponents: [
    SaveReadingsDialogComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
