import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Measurement } from '../measurement';
import { Throughput } from '../throughput';
import { BargaugeOptions } from '../../bargauge/bargauge-options';

@Component({
  selector: 'app-device-details-layout',
  templateUrl: './device-details-layout.component.html',
  styleUrls: ['./device-details-layout.component.css']
})
export class DeviceDetailsLayoutComponent implements OnInit {
  @Input() data: Measurement;
  @Input() latency: Throughput;
  @Input() averageDL: Throughput;
  @Input() averageUL: Throughput;
  @Input() dataTimeout = false;
  @Input() isTestRunning = false;
  @Input() disableRunButton = false;
  @Output() buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  public rsrpGauge: BargaugeOptions;
  public rsrqGauge: BargaugeOptions;
  public snrGauge: BargaugeOptions;

  constructor() { }

  ngOnInit() {
    this.rsrpGauge = {
      width: 180,
      minValue: -140,
      maxValue: -50,
      thresholdOne: -115,
      thresholdTwo: -105,
      thresholdThree: -95
    };
    this.rsrqGauge = {
      width: 150,
      minValue: -25,
      maxValue: 0,
      thresholdOne: -20,
      thresholdTwo: -12,
      thresholdThree: -8
    };
    this.snrGauge = {
      width: 150,
      minValue: 0,
      maxValue: 20,
      thresholdOne: 7,
      thresholdTwo: 10,
      thresholdThree: 12
    };
  }

}
