import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-readings-dialog',
  templateUrl: './save-readings-dialog.component.html',
  styleUrls: ['./save-readings-dialog.component.css']
})
export class SaveReadingsDialogComponent implements OnInit {
  name = 'Saved reading';

  constructor() { }

  ngOnInit() {
  }

}
