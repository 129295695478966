import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BargaugeOptions } from './bargauge-options';

@Component({
  selector: 'app-bargauge',
  templateUrl: './bargauge.component.html',
  styleUrls: ['./bargauge.component.scss']
})
export class BargaugeComponent implements OnInit, OnChanges {
  @Input() options: BargaugeOptions;
  @Input() currentValue: number;

  public fillWidth: number; //  = 100 * this.currentValue / (this.options.maxValue - this.options.minValue);
  public fillColor = '#EE6055';

  constructor() { }

  ngOnInit() {
    this.updateValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateValues();
  }

  updateValues() {
    if (this.currentValue > this.options.maxValue) {
      this.fillWidth = 0;
    } else {
      this.fillWidth = this.options.width * (this.currentValue - this.options.minValue) / (this.options.maxValue - this.options.minValue);
    }
    if (this.currentValue > this.options.thresholdThree) {
      this.fillColor = '#9FD356';
    } else if (this.currentValue > this.options.thresholdTwo) {
      this.fillColor = '#FF8C42';
    } else if (this.currentValue > this.options.thresholdOne) {
      this.fillColor = '#EE6055';
    } else {
      this.fillColor = '#EE6055';
    }
  }
}
